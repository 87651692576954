import { Post, PostQuery } from 'common-types'
import { Select, TextInput } from 'components/global/FormInputs'
import Layout from 'components/Layout'
import ArticleCard from 'components/news/ArticleCard'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'

import SectionTitle from '../../components/global/SectionTitle'
import { Col, Row, Section } from '../../components/grid'

export default function NewsArchivePage() {
	const posts = useStaticQuery<PostQuery>(query)
		.allContentfulPost.edges.filter((item) => item.node.featuredPost !== true)
		.map((item) => ({
			year: item.node.publishedDate.substring(item.node.publishedDate.length - 4),
			postType: item.node.category,
			postState: item.node.state,
			...item.node
		}))
	const [years] = useState(reduceYears())
	const [postTypes] = useState(reduceCategories())
	const [states] = useState(reduceStates())
	const [search, setSearch] = useState('')
	const [searchFilter, setSearchFilter] = useState(posts)
	const [filter, setFilter] = useState({ year: 'all', postType: 'all', postState: 'all' })
	const [postFilter, setPostFilter] = useState(posts)

	const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target
		setFilter((prevState) => ({ ...prevState, [name]: value }))
	}

	useEffect(() => {
		function search(post: Post) {
			// @ts-ignore
			return Object.keys(this).every((key) => post[key] === this[key] || this[key] === 'all')
		}
		const filteredPosts = posts.filter(search, filter)
		setPostFilter(filteredPosts)
		// eslint-disable-next-line
	}, [filter])

	useEffect(() => {
		const searchFilterPosts = posts.filter((post) => post.title.toLowerCase().includes(search.toLowerCase()) || post.content.raw.toLowerCase().includes(search.toLowerCase()))
		setSearchFilter(searchFilterPosts)
		// eslint-disable-next-line
	}, [search])

	function reduceYears() {
		let allYears: string[] = []
		posts.forEach((post) => allYears.push(post.publishedDate.substring(post.publishedDate.length - 4)))
		const newYears = [...new Set(allYears)]
		return newYears
	}
	function reduceCategories() {
		let allCategories: string[] = []
		posts.forEach((post) => allCategories.push(post.category))
		const newCategories = [...new Set(allCategories)]
		return newCategories
	}
	function reduceStates() {
		let allStates: string[] = []
		posts.forEach((post) => allStates.sort().push(post.state))
		const newStates = [...new Set(allStates)]
		return newStates
	}

	return (
		<Layout pageName="News">
			<Section margin="mt-20">
				<SectionTitle title="Archived Posts" />
				<Row>
					<Col>
						<div className="flex flex-col p-6 md:flex-row md:items-center">
							<svg xmlns="http://www.w3.org/2000/svg" className="mr-2 hidden h-5 w-5 text-slate-400 sm:block" viewBox="0 0 20 20" fill="currentColor">
								<path
									fillRule="evenodd"
									d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
									clipRule="evenodd"
								/>
							</svg>
							<div className="max-w-max md:mr-4">
								{postTypes && (
									<Select id="postType" name="postType" value={filter.postType} onChange={handleFilterChange}>
										{/* eslint-disable-next-line */}
										<option value="all">All Categories</option>
										{postTypes.map((post, index) => {
											return (
												<option key={`${post}-${index}`} value={post}>
													{post === 'Article' ? 'In The News' : post}
												</option>
											)
										})}
									</Select>
								)}
							</div>

							<div className="my-4 max-w-max md:my-0 ">
								{years && (
									<Select id="year" name="year" value={filter.year} onChange={handleFilterChange}>
										{/* eslint-disable-next-line */}
										<option value="all">All Years</option>
										{years.map((year, index) => {
											return (
												<option key={`${year}-${index}`} value={year}>
													{year}
												</option>
											)
										})}
									</Select>
								)}
							</div>

							<div className="max-w-max md:ml-4">
								{states && (
									<Select id="state" name="postState" value={filter.postState} onChange={handleFilterChange}>
										{/* eslint-disable-next-line */}
										<option value="all" className="pr-4">
											All States
										</option>
										{states.map((state, index) => {
											return (
												<option key={`${state}-${index}`} value={state}>
													{state}
												</option>
											)
										})}
									</Select>
								)}
							</div>
							<div className="my-4 max-w-max border border-solid md:my-0 md:ml-auto">
								<TextInput
									type="text"
									id="search"
									name="search"
									placeholder="Search all posts"
									value={search}
									search
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
									className="max-w-max"
								/>
							</div>
						</div>
					</Col>
					{!search && (
						<Col>
							{postFilter.length > 0 ? (
								postFilter.map((post) => <ArticleCard key={post.id} data={post} />)
							) : (
								<h3 className="text-center">No articles match this search</h3>
							)}
						</Col>
					)}
					{search && (
						<Col>
							{searchFilter.length > 0 ? (
								searchFilter.map((post) => <ArticleCard key={post.id} data={post} />)
							) : (
								<h3 className="text-center">No articles match this search</h3>
							)}
						</Col>
					)}
				</Row>
			</Section>
		</Layout>
	)
}

const query = graphql`
	query {
		allContentfulPost(sort: { fields: publishedDate, order: DESC }) {
			edges {
				node {
					title
					snippet
					id
					image {
						file {
							url
						}
						title
					}
					content {
						raw
					}
					category
					state
					featuredPost
					source
					publishedDate(formatString: "MMMM DD, YYYY")
					slug
				}
			}
		}
	}
`
